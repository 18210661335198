/** @format */

import React, {useEffect} from "react";
import { Link } from 'react-router-dom';

/**styles */
import "../style/pcom-reskin/_ganked.scss";






export default function Buttons() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  function stopIt(e) {
    e.preventDefault();
    e.stopPropagation();
    console.debug("No.");
  }

  return (
    <>
    <div style={{minHeight: '100vh', backgroundColor: '#444'}}>
      <div className="PCOM-GANKED" style={{padding: '2rem', display: 'flex', flexDirection: 'column', gap: '1rem'}}>
        <h1 style={{color: 'white', margin: '0'}}>Buttons <em>—on hold</em></h1>

        <h2 style={{color: 'white', margin: '0'}}>Flood Buttons</h2>

        <p style={{color: 'white', margin: '0'}}>Hover to see the effect</p>

        <h3 style={{color: 'white', margin: '0'}}>Bottom Up</h3>

        <div style={{display: 'flex', flexDirection: 'row', gap: '1em', padding: '1rem', backgroundColor: 'var(--color--ui-background-light)'}}>
          <div className="use-button-primary use-button-effect-flood use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-secondary use-button-effect-flood use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-tertiary use-button-effect-flood use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-action use-button-effect-flood use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', gap: '1em', padding: '1rem', backgroundColor: 'var(--color--ui-background-med)'}}>
          <div className="use-button-primary use-button-effect-flood use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-secondary use-button-effect-flood use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-tertiary use-button-effect-flood use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-action use-button-effect-flood use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', gap: '1em', padding: '1rem', backgroundColor: 'var(--color--ui-background-med-gray)'}}>
          <div className="use-button-primary use-button-effect-flood use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-secondary use-button-effect-flood use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-tertiary use-button-effect-flood use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-action use-button-effect-flood use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', gap: '1em', padding: '1rem', backgroundColor: 'var(--color--ui-background-dark)'}}>
          <div className="use-button-primary-inverse use-button-effect-flood use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-secondary-inverse use-button-effect-flood use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-tertiary-inverse use-button-effect-flood use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-action-inverse use-button-effect-flood use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', gap: '1em', padding: '1rem', backgroundColor: 'var(--color--ui-03)'}}>
          <div className="use-button-primary-inverse use-button-effect-flood use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-secondary-inverse use-button-effect-flood use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-tertiary-inverse use-button-effect-flood use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-action-inverse use-button-effect-flood use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
        </div>








        <h3 style={{color: 'white', margin: '0'}}>Lef to Right</h3>

        <div style={{display: 'flex', flexDirection: 'row', gap: '1em', padding: '1rem', backgroundColor: 'var(--color--ui-background-light)'}}>
          <div className="use-button-primary use-button-effect-flood flood-left-to-right use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-secondary use-button-effect-flood flood-left-to-right use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-tertiary use-button-effect-flood flood-left-to-right use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-action use-button-effect-flood flood-left-to-right use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', gap: '1em', padding: '1rem', backgroundColor: 'var(--color--ui-background-med)'}}>
          <div className="use-button-primary use-button-effect-flood flood-left-to-right use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-secondary use-button-effect-flood flood-left-to-right use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-tertiary use-button-effect-flood flood-left-to-right use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-action use-button-effect-flood flood-left-to-right use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', gap: '1em', padding: '1rem', backgroundColor: 'var(--color--ui-background-med-gray)'}}>
          <div className="use-button-primary use-button-effect-flood flood-left-to-right use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-secondary use-button-effect-flood flood-left-to-right use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-tertiary use-button-effect-flood flood-left-to-right use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-action use-button-effect-flood flood-left-to-right use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', gap: '1em', padding: '1rem', backgroundColor: 'var(--color--ui-background-dark)'}}>
          <div className="use-button-primary-inverse use-button-effect-flood flood-left-to-right use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-secondary-inverse use-button-effect-flood flood-left-to-right use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-tertiary-inverse use-button-effect-flood flood-left-to-right use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-action-inverse use-button-effect-flood flood-left-to-right use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', gap: '1em', padding: '1rem', backgroundColor: 'var(--color--ui-03)'}}>
          <div className="use-button-primary-inverse use-button-effect-flood flood-left-to-right use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-secondary-inverse use-button-effect-flood flood-left-to-right use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-tertiary-inverse use-button-effect-flood flood-left-to-right use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-action-inverse use-button-effect-flood flood-left-to-right use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
        </div>












        <h3 style={{color: 'white', margin: '0'}}>Primary Gradient</h3>

        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div style={{display: 'flex', flexDirection: 'row', gap: '1em', padding: '1rem', backgroundColor: 'var(--color--ui-background-light)'}}>
            <div className="use-button-primary use-button-effect-flood flood-primary-gradient use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
                <p>
                  <a href="#" onClick={stopIt}>
                    <span>Primary Button Long Label</span>
                  </a>
                </p>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', gap: '1em', padding: '1rem', backgroundColor: 'var(--color--ui-background-med)'}}>
            <div className="use-button-primary use-button-effect-flood flood-primary-gradient use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
                <p>
                  <a href="#" onClick={stopIt}>
                    <span>Primary Button Long Label</span>
                  </a>
                </p>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', gap: '1em', padding: '1rem', backgroundColor: 'var(--color--ui-background-med-gray)'}}>
            <div className="use-button-primary use-button-effect-flood flood-primary-gradient use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
                <p>
                  <a href="#" onClick={stopIt}>
                    <span>Primary Button Long Label</span>
                  </a>
                </p>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', gap: '1em', padding: '1rem', backgroundColor: 'var(--color--ui-background-dark)'}}>
            <div className="use-button-primary-inverse use-button-effect-flood flood-primary-gradient use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
                <p>
                  <a href="#" onClick={stopIt}>
                    <span>Primary Button Long Label</span>
                  </a>
                </p>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', gap: '1em', padding: '1rem', backgroundColor: 'var(--color--ui-03)'}}>
            <div className="use-button-primary-inverse use-button-effect-flood flood-primary-gradient use-button-effect-disable-zoom-on-hover use-button-effect-focus-fix">
                <p>
                  <a href="#" onClick={stopIt}>
                    <span>Primary Button Long Label</span>
                  </a>
                </p>
            </div>
          </div>
        </div>












        <h2 style={{color: 'white', margin: '0'}}>Inner-Ripple Buttons</h2>

        <p style={{color: 'white', margin: '0'}}>Grows on hover, click to see the ripple and bounce effects</p>

        <div style={{display: 'flex', flexDirection: 'row', gap: '1em', padding: '1rem', backgroundColor: 'var(--color--ui-background-light)'}}>
          <div className="use-button-primary use-button-effect-inner-ripple use-button-effect-squish-on-click use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span className="effects"></span>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-secondary use-button-effect-inner-ripple use-button-effect-squish-on-click use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span className="effects"></span>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-tertiary use-button-effect-inner-ripple use-button-effect-squish-on-click use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span className="effects"></span>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-action use-button-effect-inner-ripple use-button-effect-squish-on-click use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span className="effects"></span>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', gap: '1em', padding: '1rem', backgroundColor: 'var(--color--ui-background-med)'}}>
          <div className="use-button-primary use-button-effect-inner-ripple use-button-effect-squish-on-click use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span className="effects"></span>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-secondary use-button-effect-inner-ripple use-button-effect-squish-on-click use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span className="effects"></span>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-tertiary use-button-effect-inner-ripple use-button-effect-squish-on-click use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span className="effects"></span>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-action use-button-effect-inner-ripple use-button-effect-squish-on-click use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span className="effects"></span>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', gap: '1em', padding: '1rem', backgroundColor: 'var(--color--ui-background-med-gray)'}}>
          <div className="use-button-primary use-button-effect-inner-ripple use-button-effect-squish-on-click use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span className="effects"></span>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-secondary use-button-effect-inner-ripple use-button-effect-squish-on-click use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span className="effects"></span>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-tertiary use-button-effect-inner-ripple use-button-effect-squish-on-click use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span className="effects"></span>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-action use-button-effect-inner-ripple use-button-effect-squish-on-click use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span className="effects"></span>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', gap: '1em', padding: '1rem', backgroundColor: 'var(--color--ui-background-dark)'}}>
          <div className="use-button-primary-inverse use-button-effect-inner-ripple use-button-effect-squish-on-click use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span className="effects"></span>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-secondary-inverse use-button-effect-inner-ripple use-button-effect-squish-on-click use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span className="effects"></span>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-tertiary-inverse use-button-effect-inner-ripple use-button-effect-squish-on-click use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span className="effects"></span>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-action-inverse use-button-effect-inner-ripple use-button-effect-squish-on-click use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span className="effects"></span>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', gap: '1em', padding: '1rem', backgroundColor: 'var(--color--ui-03)'}}>
          <div className="use-button-primary-inverse use-button-effect-inner-ripple use-button-effect-squish-on-click use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span className="effects"></span>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-secondary-inverse use-button-effect-inner-ripple use-button-effect-squish-on-click use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span className="effects"></span>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-tertiary-inverse use-button-effect-inner-ripple use-button-effect-squish-on-click use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span className="effects"></span>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
          <div className="use-button-action-inverse use-button-effect-inner-ripple use-button-effect-squish-on-click use-button-effect-focus-fix">
              <p>
                <a href="#" onClick={stopIt}>
                  <span className="effects"></span>
                  <span>Example Button</span>
                </a>
              </p>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

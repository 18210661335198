/** @format */

import React, {useEffect, useState} from "react";
import { Link } from 'react-router-dom';

/**styles */
import "../style/pcom-reskin/_ganked.scss";






export default function WordTransitionsB() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  function stopIt(e) {
    e.preventDefault();
    e.stopPropagation();
    console.debug("No.");
  }

  const [animateA, setAnimateA] = useState(false);
  const handleClickA = () => {
    setAnimateA((prev) => !prev); // Toggle the state
  };

  const [animateB, setAnimateB] = useState(false);
  const handleClickB = () => {
    setAnimateB((prev) => !prev); // Toggle the state
  };

  const [animateC, setAnimateC] = useState(false);
  const handleClickC = () => {
    setAnimateC((prev) => !prev); // Toggle the state
  };

  return (
    <>

    <div style={{minHeight: '100vh', backgroundColor: '#4F4F4F'}}>
      <div className="PCOM-GANKED home-page" style={{padding: '2rem'}}>

        <h1 style={{color: 'white', margin: '0 0 2rem 0'}}>Word Transitions — B</h1>




        {/*======= Reveal =======*/}
        <div className={`animated ${animateB ? "in-view" : ""}`} style={{margin: '0 0 2rem 0'}}>
          <button
            style={{margin: '0 0 1rem', padding: '5px'}}
            onClick={handleClickB}
            disabled={animateB ? "true" : ""}
            >
            Start Animation
          </button>

          <div className="carousel-with-transitions use-text-clipper-b use-button-tertiary-inverse ">
            <h2>Where learning comes to life</h2>
            <ul>
              <li data-text="For School">For School</li>
              <li data-text="For College">For College</li>
              <li data-text="For Work">For Work</li>
              <li data-text="at every learning stage">at every learning stage</li>
            </ul>

            <p>Learning is a journey. Pearson is your guide.</p>

            <p><a href="#" onClick={stopIt}>Get to know Pearson</a></p>
          </div>
        </div>


      </div>
    </div>
    </>
  );
}

/** @format */

import React, {useEffect, useState} from "react";
import { Link } from 'react-router-dom';

/**styles */
import "../style/pcom-reskin/_ganked.scss";






export default function WordTransitionsA() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  function stopIt(e) {
    e.preventDefault();
    e.stopPropagation();
    console.debug("No.");
  }

  const [animateA, setAnimateA] = useState(false);
  const handleClickA = () => {
    setAnimateA((prev) => !prev); // Toggle the state
  };


  return (
    <>

    <div style={{minHeight: '100vh', backgroundColor: '#4F4F4F'}}>
      <div className="PCOM-GANKED home-page" style={{padding: '2rem'}}>

        <h1 style={{color: 'white', margin: '0 0 2rem 0'}}>Word Transitions — A</h1>



        {/*======= Clipper Sliding Square =======*/}
        <div className={`animated ${animateA ? "in-view" : ""}`} style={{margin: '0 0 2rem 0'}}>
          <button
            style={{margin: '0 0 1rem', padding: '5px'}}
            onClick={handleClickA}
            disabled={animateA ? "true" : ""}
            >
            Start Animation
          </button>

          <div className="carousel-with-transitions use-text-clipper-a use-button-tertiary-inverse">
            <h2>Where learning comes to life</h2>
            <ul>
              <li>For School</li>
              <li>For College</li>
              <li>For Work</li>
              <li>at every learning stage</li>
            </ul>

            <p>Learning is a journey. Pearson is your guide.</p>

            <p><a href="#" onClick={stopIt}>Get to know Pearson</a></p>
          </div>
        </div>

      </div>
    </div>
    </>
  );
}

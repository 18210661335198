/** @format */

import React, {useEffect} from "react";
import { Link } from 'react-router-dom';

/**styles */
import "../style/pcom-reskin/_ganked.scss";






export default function ButtonsUnused() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  function stopIt(e) {
    e.preventDefault();
    e.stopPropagation();
    console.debug("No.");
  }

  return (
    <>
    <div style={{minHeight: '100vh', backgroundColor: '#444'}}>


      <div className="PCOM-GANKED home-page" style={{padding: '2rem'}}>

        <h1 style={{color: 'white'}}>Buttons — Previous Studies</h1>

        <hr style={{borderTop: '1px solid #FFF6'}} />
        <ul>
          <li style={{color: 'white'}}>Hover over a button to see the <em>hover</em> state</li>
          <li style={{color: 'white'}}>Click a button to see the <em>active</em> state</li>
        </ul>
      </div>

      <div className="PCOM-GANKED home-page" style={{padding: '2rem', display: 'grid', gap: '2rem', gridTemplateColumns: '1fr 1fr 1fr'}}>
        <div style={{backgroundColor: 'var(--color--ui-01)', padding: '2rem', borderRadius: 'var(--corners-semiround-md)'}}>
          <p><a href="https://www.pearson.com/en-us/schools/college-readiness-career-education.html" onClick={stopIt} className="stopIt OVERRIDE-HOME card-button card-button-motion">
              Grow &amp; Ripple
              <svg className="icon-arrow" width="16" height="16" viewBox="0 0 16 16" aria-hidden="true">
                <g fill-rule="evenodd">
                  <path className="icon-arrow__line-path" d="M13.13,7H1c-.55,0-1,.45-1,1s.45,1,1,1h12.13c.55,0,1-.45,1-1s-.45-1-1-1Z"/>
                  <path className="icon-arrow__tip-path" d="M6.71,7.29L1.71,2.29c-.39-.39-1.02-.39-1.41,0-.39.39-.39,1.02,0,1.41,1.1,1.1,2.2,2.2,3.29,3.29h0s.28.29.28.29c.39.39.39,1.02,0,1.41l-.28.28h0c-1.1,1.11-2.2,2.2-3.29,3.3-.39.39-.39,1.02,0,1.41.39.39,1.02.39,1.41,0l5-5c.39-.39.39-1.02,0-1.41Z"/>
                </g>
              </svg>
            </a></p>
            <p>Grow on hover, ripple on click</p>
        </div>


        <div style={{backgroundColor: 'var(--color--ui-background-dark)', padding: '2rem', borderRadius: 'var(--corners-semiround-md)'}}>
          <p>
            <button class="primary-button-container motion signin" style={{marginLeft: '0'}}>
              Grow &amp; Ripple Inverse
              <svg class="icon-arrow" width="16" height="16" viewBox="0 0 16 16" aria-hidden="true"><g fill-rule="evenodd"><path class="icon-arrow__line-path" d="M13.13,7H1c-.55,0-1,.45-1,1s.45,1,1,1h12.13c.55,0,1-.45,1-1s-.45-1-1-1Z"></path><path class="icon-arrow__tip-path" d="M6.71,7.29L1.71,2.29c-.39-.39-1.02-.39-1.41,0-.39.39-.39,1.02,0,1.41,1.1,1.1,2.2,2.2,3.29,3.29h0s.28.29.28.29c.39.39.39,1.02,0,1.41l-.28.28h0c-1.1,1.11-2.2,2.2-3.29,3.3-.39.39-.39,1.02,0,1.41.39.39,1.02.39,1.41,0l5-5c.39-.39.39-1.02,0-1.41Z"></path></g></svg>
            </button>
            </p>
            <p style={{color: 'white'}}>Grow on hover, ripple on click</p>
        </div>

        <div></div>


        <div className="use-button-primary-inverse use-button-primary-inverse"  style={{backgroundColor: 'var(--color--ui-background-dark)', padding: '2rem', borderRadius: 'var(--corners-semiround-md)'}}>
          <p><a href="https://www.pearson.com/en-us/pearsonplus/search.html" onClick={stopIt} className="stopIt OVERRIDE-HOME primarybtn radiant-a">Grow &amp; Pulse – Blue & Fuchsia</a></p>
          <p style={{color: 'white'}}>Grow and pulse outline on hover</p>
        </div>

        <div className="use-button-primary-inverse use-button-primary-inverse"  style={{backgroundColor: 'var(--color--ui-background-dark)', padding: '2rem', borderRadius: 'var(--corners-semiround-md)'}}>
          <p><a href="https://www.pearson.com/en-us/pearsonplus/search.html" onClick={stopIt} className="stopIt OVERRIDE-HOME primarybtn radiant-b">Grow &amp; Pulse – Yellow & Purple</a></p>
          <p style={{color: 'white'}}>Grow and pulse outline on hover</p>
        </div>

        <div className="use-button-primary-inverse use-button-primary-inverse"  style={{backgroundColor: 'var(--color--ui-background-dark)', padding: '2rem', borderRadius: 'var(--corners-semiround-md)'}}>
          <p><a href="https://www.pearson.com/en-us/pearsonplus/search.html" onClick={stopIt} className="stopIt OVERRIDE-HOME primarybtn radiant-c">Grow &amp; Pulse – Blue & Purple</a></p>
          <p style={{color: 'white'}}>Grow and pulse outline on hover</p>
        </div>
      </div>

    </div>
    </>
  );
}

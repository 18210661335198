/** @format */

import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { PcomLoginState, SmsLoginState } from "../recoil/recoilAtom";

export default function Start() {
  const [PcomLoggedIn, setPcomLoggedIn] = useRecoilState(PcomLoginState);
  const [SmsLoggedIn, setSmsLoggedIn] = useRecoilState(SmsLoginState);

  useEffect(() => {
      window.scrollTo(0, 0)
  }, []);

  // Reset recoil logged in states to false, so top nav always starts in logged out state
  setPcomLoggedIn(false);
  setSmsLoggedIn(false);

  console.debug('PcomLoggedIn', PcomLoggedIn);
  console.debug('SmsLoggedIn', SmsLoggedIn);

  return (
      <div style={{ minHeight: "100vh", margin: "0 auto", maxWidth: "30em" }}>
          <h1>ASUGSV End-to-End</h1>

          <ul>
            <li style={{display: 'flex', justifyContent: 'space-between'}}><Link to={`${process.env.PUBLIC_URL}/home`}>Home (aka Super gate way)</Link> <span>[<Link to='https://www.pearson.com/en-us.html' target="_blank">Source</Link>]</span></li>

            <li style={{display: 'flex', justifyContent: 'space-between'}}><Link to={`${process.env.PUBLIC_URL}/higher-education`}>HE for colleges</Link> <span>[<Link to='https://www.pearson.com/en-us/higher-education.html' target="_blank">Source</Link>]</span></li>

            <li style={{display: 'flex', justifyContent: 'space-between'}}><Link to={`${process.env.PUBLIC_URL}/higher-education/students`}>Students</Link> <span>[<Link to='https://www.pearson.com/en-us/higher-education/students.html' target="_blank">Source</Link>]</span></li>

            <li style={{display: 'flex', justifyContent: 'space-between'}}><Link to={`${process.env.PUBLIC_URL}/isbn-search`}>PLP (Search Results) — and Ecom flow</Link> <span>[<Link to='https://www.pearson.com/en-us/search.html?aq=campbells' target="_blank">Example</Link>]</span></li>
          </ul>

          <hr />
            <h2>Motion Prototypes</h2>
            <ul>


              <li style={{display: 'flex', justifyContent: 'space-between'}}><Link to={`${process.env.PUBLIC_URL}/hero-ripples`}>Hero Ripples <em>— on hold</em></Link></li>

              <li style={{display: 'flex', justifyContent: 'space-between'}}><Link to={`${process.env.PUBLIC_URL}/buttons`}>Buttons <em>— on hold</em></Link> <span>[<Link to={`${process.env.PUBLIC_URL}/buttons-unused`}>Previous studies</Link>]</span></li>

              <li style={{display: 'flex', justifyContent: 'space-between'}}><Link to={`${process.env.PUBLIC_URL}/charts`}>Chart Tests</Link></li>

              <li style={{display: 'flex', justifyContent: 'space-between'}}>Word Transitions:
                <Link to={`${process.env.PUBLIC_URL}/word-transitions-a`}>&nbsp; A &nbsp;</Link>
                <Link to={`${process.env.PUBLIC_URL}/word-transitions-b`}>&nbsp; B &nbsp;</Link>
                <Link to={`${process.env.PUBLIC_URL}/word-transitions-c`}>&nbsp; C &nbsp;</Link>
              </li>
            </ul>
          <hr />

          <h2 style={{'opacity': '0.25'}}>Stretch Goals</h2>
          <ul>
            <li style={{'opacity': '0.25', display: 'flex', justifyContent: 'space-between'}}><Link to={`${process.env.PUBLIC_URL}/higher-education/educators`}>Educators</Link> <span>[<Link to='https://www.pearson.com/en-us/higher-education/educators.html' target="_blank">Source</Link>]</span></li>
            <li style={{'opacity': '0.25'}}>About Us
              <ul>
                 <li style={{display: 'flex', justifyContent: 'space-between'}}>Careers <span>[<Link to='https://pearson.jobs/' target="_blank">Source</Link>]</span></li>
                 <li style={{display: 'flex', justifyContent: 'space-between'}}>Investor Relations <span>[<Link to='https://plc.pearson.com/en-GB/investors' target="_blank">Source</Link>]</span></li>
                 <li style={{display: 'flex', justifyContent: 'space-between'}}>Our Company <span>[<Link to='https://plc.pearson.com/en-GB/company' target="_blank">Source</Link>]</span></li>
                 <li style={{display: 'flex', justifyContent: 'space-between'}}>Diversity &amp; Inclusion <span>[<Link to='https://plc.pearson.com/en-GB/careers/diversity-equity-inclusion' target="_blank">Source</Link>]</span></li>
              </ul>
            </li>
          </ul>



          <div style={{'display': 'none'}}>
            <hr />

            <h2>P.com</h2>
            <ul>
                <li>
                    1.1: <Link to={`${process.env.PUBLIC_URL}/isbn-search`} target="_blank">ISBN Search</Link>
                    <details>
                        <summary>Path</summary>
                        <ul>
                            <li>5.1: PDP (P.com)</li>
                            <li>6.1: Cart + Course Registration (P.com) </li>
                            <li>7.1: Sign In / Registration (IES)</li>
                            <li>8.1: Checkout (P.com)</li>
                            <li>9.1A: Order Confirmation (P.com)</li>
                        </ul>
                    </details>
                </li>

                <li style={{ marginTop: "1em" }}>
                    1.2: <Link to={`${process.env.PUBLIC_URL}/mastering`} target="_blank">Mastering Marketing Page</Link>
                    <details>
                        <summary>Path</summary>
                        <ul>
                            <li>2.1: Get Registered</li>
                            <li>3.1: Course Reg with ID (SMS Platform)</li>
                            <li>4.1: Sign In / Registration (IES)</li>
                            <li>5.2: Access selection (SMS Platform)</li>
                            <li>8.1: Checkout (P.com)</li>
                            <li>9.1A: Order Confirmation (P.com)</li>
                        </ul>
                    </details>

                </li>

                <li style={{ marginTop: "1em" }}>
                    1.3: <Link to={`${process.env.PUBLIC_URL}/mylab`} target="_blank">MyLab Marketing Page</Link>
                    <details>
                        <summary>Path</summary>
                        <ul>
                            <li>2.1: Get Registered</li>
                            <li>3.1: Course Reg with ID (SMS Platform)</li>
                            <li>4.1: Sign In / Registration (IES)</li>
                            <li>5.2: Access selection (SMS Platform)</li>
                            <li>8.1: Checkout (P.com)</li>
                            <li>9.1A: Order Confirmation (P.com)</li>
                        </ul>
                    </details>
                </li>
            </ul>

            <h2>CaaS</h2>
            <ul>
                <li style={{ marginTop: "1em" }}>
                    1.1 <Link to={`${process.env.PUBLIC_URL}/instructor-invite`} target="_blank">Instructor Course Invite</Link>
                    <details>
                        <summary>Path</summary>
                        <ul>
                            <li>4.1: Sign In / Registration (IES)</li>
                            <li>5.1: Access selection (SMS Platform)</li>
                        </ul>
                    </details>
                </li>

                <li style={{marginTop: '1em'}}>
                    1.2: <Link to={`${process.env.PUBLIC_URL}/mlm-marketing`} target="_blank">MLM Marketing Page</Link>
                    <details>
                        <summary>Path</summary>
                        <ul>
                            <li>2.1: Get Registered</li>
                            <li>3.1: Course Reg with ID (SMS Platform)</li>
                            <li>4.1: Sign In / Registration (IES)</li>
                            <li>5.1: Access selection (SMS Platform)</li>
                        </ul>
                    </details>
                </li>
            </ul>

            <p style={{width: '0', height: '0', overflow: 'hidden'}}>Forcing SF to load <span style={{ fontFamily: "SF Compact Display"}}>sf-compact</span> and <span style={{ fontFamily: "SF Compact Rounded"}}>sf-compact-rounded</span></p>
          </div>

      </div>
  );
}

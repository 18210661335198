/** @format */

import React, {useEffect} from "react";
import { Link } from 'react-router-dom';

/**styles */
import "../style/pcom-reskin/_ganked.scss";


import circlesFuchsia from '../assets/image/motion/circles-fuchsia.jpg';



export default function HeroRipples() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  function stopIt(e) {
    e.preventDefault();
    e.stopPropagation();
    console.debug("No.");
  }

  return (
    <>
    <div style={{minHeight: '100vh', backgroundColor: '#444'}}>
      <div className="PCOM-GANKED" style={{padding: '2rem', display: 'flex', flexDirection: 'column', gap: '1rem'}}>
        <h1 style={{color: 'white'}}>Hero Ripples <em>— on hold</em></h1>


        <div>
          {/*Layer the ripple effect on top of the following img*/}
          <img src={circlesFuchsia} />
        </div>



      </div>

    </div>
    </>
  );
}
